import React, { useState } from 'react';
import styled from 'styled-components';
import { t } from '../language/config';
import mailImage from '../images/buzon.png';
import useAuth from '../hooks/useAuth';

const StyledEmailSentView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 30px;
  width: 100%;
  box-sizing: border-box;

  #resend-again {
    margin-top: 0;
    color: #75B753;
  }
`;

const Text = styled.span`
  font-size: 1.6rem;
  color: #333;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  font-weight: 300;
  ${({ strong }) => strong ? 'font-weight: 600;' : ''}
`;

const ImageWrapper = styled.div`
  width: 205px;
  display: flex;
  text-align: center;
  margin: 20px 0 0 0;
`;

const UserEmail = styled.p`
  font-weight: bold;
`;

const Title = styled.span`
  display: block;
  font-size: 24px;
  font-weight: bold;
  ${({ theme }) => `
    color: ${theme.colors.blue};
  `};
  letter-spacing: 0;
  line-height: 47px;
  margin: 10px 0 0;
`;

const LinkResend = styled.span`
  font-weight: bold;
  font-size: 16px;
  color: #1B75BB;
  letter-spacing: 0;
  line-height: 21px;
  margin: 30px 0 0 5px;
  cursor: pointer;
  text-decoration: none;
`;

const ContactUs = styled.a`
  font-size: 16px;
  color: #333;
  letter-spacing: 0;
  line-height: 21px;
  margin: 20px 0 0;
  text-align: center;
  font-weight: 600;
  text-decoration: underline;
`;

const Image = styled.img.attrs(() => ({
  src: mailImage,
  alt: 'Email Sent',
}))`
  width: 100%;
  align-self: flex-start;
`;

const EmailSentView = ({ email }) => {
  const [emailSent, setEmailSent] = useState(false);
  const { sendResetPasswordEmail } = useAuth();

  const handleResend = () => {
    sendResetPasswordEmail(email);
    setEmailSent(true);
  };

  return (
    <StyledEmailSentView>
      <ImageWrapper id="mail">
        <Image />
      </ImageWrapper>
      <Title className="title">{t('EMAIL_SENT_VIEW.TITLE')}</Title>
      {emailSent && <Text id="resend-again">The email was sent.</Text>}
      <Text>{t('EMAIL_SENT_VIEW.SUBTITLE')}</Text>
      <UserEmail>{email}</UserEmail>
      <Text>
        {t('EMAIL_SENT_VIEW.DESC_P1')}
        <LinkResend onClick={handleResend}>
          {t('EMAIL_SENT_VIEW.CLICK_HERE')}
        </LinkResend>
        {t('EMAIL_SENT_VIEW.DESC_P2')}
        <ContactUs href={`mailto:${t('CONTACT_MAIL')}`}>
          {t('EMAIL_SENT_VIEW.CONTACT_US')}
        </ContactUs>
      </Text>
    </StyledEmailSentView>
  );
};

export default EmailSentView;
