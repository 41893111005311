import React, { useReducer, useMemo, useState, useEffect } from 'react';
import { Texter, Breakpoints, Modal } from 'kinedu-react-components';
import Button from 'components/extended/Button';
import GoogleButton from '../components/GoogleButton';
import styled from 'styled-components';
import Divider from '../components/Divider';
import useAuth from '../hooks/useAuth';
import TexterBasic from '../styles/themes/TexterBasic';
import { t } from '../language/config';
import { PROJECT_PATH } from '../config';
import { useTranslation } from 'react-i18next';
import EmailSentView from './EmailSentView';
import ForgotPasswordView from './ForgotPasswordView';

const Title = styled.span`
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0;
  margin: 10px 0 0;
  line-height: 30px;
  ${({ theme }) => `
    color: ${theme.colors.blue};
    ${theme.breakPoints.tablet} {
      font-size: 2.4rem;
    }
  `};
`;

const Subtitle = styled.span`
  font-size: 1.6rem;
  ${({ theme }) => `
    ${theme.breakPoints.tablet} {
      font-size: 2rem;
    }
  `}
  color: #333333;
  font-weight: 300;
  margin-bottom: 30px;
`;

const StyledAuthView = styled.div.attrs(() => ({
  className: 'AuthView',
}))`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Form = styled.form`
  width: 100%;
  text-align: center;
  & > .texter {
    margin: 0 0 1rem;
  }
`;

const Footer = styled.div`
  margin: 10px 0 0;

  button {
    font-size: 12px;
    padding: 0 0 0 5px;
    ${Breakpoints.largePhone} {
      font-size: 16px;
    }
  }
`;

const Note = styled.span`
  color: #657480;
  font-weight: 100;
  margin-top: 20px;
  font-size: 13px;
  ${Breakpoints.largePhone} {
    font-size: 16px;
  }
`;

const ForgotPasswordLink = styled.p`
  ${({ theme }) => `
    color: ${theme.colors.blue};
  `};
  font-size: 1.4rem;
  font-weight: bold;
  text-align: right;
  letter-spacing: 0;
  cursor: pointer;
  margin: 10px 0;
  text-decoration: underline;
`;

const ErrorMessage = styled.p`
  color: #ff0039;
  font-size: 12px;
  font-weight:300;
  padding: 0px 0px;
  margin: 0;
  text-transform: initial;
  text-align: right;
  ${({ children }) => !children ? '' : `
    position: relative;
    top: -6px;
  `}

  &::first-letter {
    text-transform: uppercase;
  }
`;

const SignUpText = styled.p.attrs(() => ({
  className: 'SignUpText',
}))`
  font-weight: 300;
  color: #333;
  font-size: 1.4rem;
  text-align: center;
  a {
    font-weight: 600;
    background-color: transparent;
    outline: none;
    border: none;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 5px;
    ${({ theme }) => `
      color: ${theme?.colors?.primary};
    `}
    text-decoration: underline;
  }
`;

const SignInText = styled(SignUpText)`
  a {
    text-decoration: underline;
    font-weight: 600;
    margin-left: 5px;
    ${({ theme }) => `
      color: ${theme?.colors?.blue};

    `}
  }
`;

const SubmitButtonContainer = styled.div`
  margin: 2rem 0;
  button {
    max-width: 290px;
    margin: 0 auto;
  }
`;

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_INPUT_VALUE':
      return { ...state, [action.name]: action.value };
    default:
      throw new Error();
  }
};

const initialState = {
  email: '',
  password: '',
  password_confirmation: '',
};

const AuthView = ({
  isLogin,
  onForgotPassword,
  isSignUpVersion,
  onClickSignUp,
  onClickSignIn,
  email = '',
}) => {
  const [data, dispatch] = useReducer(reducer, initialState);

  const [isBusy, setIsBusy] = useState(false);

  const { onLogin, onSignup, errors, setErrors } = useAuth();

  const isSignUp = useMemo(() => !isLogin, [isLogin]);

  const [isForgotPassModalOpen, setIsForgotPassModalOpen] = useState(false);

  const [emailSent, setEmailSent] = useState('');

  const { i18n } = useTranslation();

  const handleOnChange = (name, value) => {
    dispatch({ type: 'SET_INPUT_VALUE', name, value });
  };

  const handleOnClickSignIn = () => {
    if (onClickSignIn) {
      onClickSignIn();
    }
  };

  const handleOnClickSignUp = () => {
    if (onClickSignUp) {
      onClickSignUp();
    }
  };

  const handleOnForgotPassword = () => {
    setIsForgotPassModalOpen(true);

    if (onForgotPassword) {
      onForgotPassword();
    }
  };

  const hasErrors = (errorsObject) => {
    let found = false;

    Object.keys(errorsObject)
      .forEach(key => {
        if ((!errorsObject[key] && errorsObject[key] !== '') && !found) {
          found = true;
        }
      });

    return found;
  };

  const handleOnSubmitForm = (event) => {
    event.preventDefault();

    const errorsList = {
      ...errors,
      emailError: data.email === '' ? t('ERRORS.EMAIL_REQUIRED') : '',
      passError: data.password === '' ? t('ERRORS.PASSWORD_REQUIRED') : '',
    };

    if (isSignUp) {
      const doPasswordsMatch = data.password === data.password_confirmation;

      if (!doPasswordsMatch) {
        errorsList.passError = t('ERRORS.PASSWORD_CONFIRMATION');
      }
    }

    setErrors(errorsList);

    if (isBusy) {
      return;
    }

    setIsBusy(true);

    if (hasErrors(errorsList)) {
      return;
    }

    if (isSignUp) {
      onSignup(data)
        .finally(() => {
          setIsBusy(false);
        });
    } else {
      onLogin(data)
        .finally(() => {
          setIsBusy(false);
        });
    }

    event.stopPropagation();
  };

  useEffect(() => {
    dispatch({ type: 'SET_INPUT_VALUE', name: 'email', value: email });
  }, [email]);

  const signUpLink = useMemo(() => {
    if (i18n.language === 'en') {
      return `${PROJECT_PATH()}/signup`;
    }

    return `${PROJECT_PATH()}/${i18n.language}/signup`;
  }, [i18n]);

  const signInLink = useMemo(() => {
    if (i18n.language === 'en') {
      return `${PROJECT_PATH()}/signin`;
    }

    return `${PROJECT_PATH()}/${i18n.language}/signin`;
  }, [i18n]);

  return (
    <StyledAuthView>
      <Title>
        {isSignUp ? t('AUTH_VIEW.CREATE_ACCOUNT') : t('AUTH_VIEW.WELCOME_BACK')}
      </Title>
      <Subtitle>
        {isSignUp
          ? t('AUTH_VIEW.REGISTER_TO_START')
          : t('AUTH_VIEW.EXISTING_ACCOUNT')}
      </Subtitle>
      {!isSignUp &&
      <>
        <GoogleButton altVersion>
          {isSignUp ? t('SIGNUP_WITH_GOOGLE') : t('LOGIN_WITH_GOOGLE')}
        </GoogleButton>
        <Divider text={t('OR')} />
      </>
      }
      <Form onSubmit={handleOnSubmitForm}>
        <Texter
          type="email"
          value={data.email}
          required
          theme={TexterBasic}
          label={t('EMAIL')}
          onChange={value => handleOnChange('email', value)}
        />
        {errors?.emailError && (
          <ErrorMessage>
            {t(errors.emailError)}
          </ErrorMessage>
        )}
        <Texter
          required
          type="password"
          theme={TexterBasic}
          label={t('PASSWORD')}
          onChange={value => handleOnChange('password', value)}
        />

        {errors?.passError && (
          <ErrorMessage>
            {t(errors.passError)}
          </ErrorMessage>
        )}

        {errors?.generalError && (
          <ErrorMessage>
            {t(errors.generalError)}
          </ErrorMessage>
        )}

        {isSignUp && (
          <>
            <Texter
              type="password"
              required
              theme={TexterBasic}
              label={t('PASSWORD_CONFIRMATION')}
              onChange={value => handleOnChange('password_confirmation', value)}
            />
            <ErrorMessage>
              {t(errors.passError || errors.generalError)}
            </ErrorMessage>
          </>
        )}

        {isLogin && (
          <ForgotPasswordLink onClick={handleOnForgotPassword}>
            {t('AUTH_VIEW.FORGOT_PASSWORD')}
          </ForgotPasswordLink>
        )}

        <SubmitButtonContainer>
          <Button
            dense
            color="primary"
            shadowColored
            fill
            type="submit"
          >
            {isSignUp ? t('SIGNUP') : t('LOGIN')}
          </Button>
        </SubmitButtonContainer>

      </Form>

      {
        isSignUp ? (
          <SignInText>
            {t('AUTH_VIEW.ALREADY_HAVE_ACCOUNT')}
            <a href={signInLink}>
              {t('LOGIN')}
            </a>
          </SignInText>
        ) : (
          <SignUpText>
            {t('AUTH_VIEW.DONT_HAVE_ACCOUNT_YET')}
            <a href={signUpLink}>
              {t('SIGNUP')}
            </a>
          </SignUpText>
        )
      }

      {isSignUpVersion ? (
        <Footer>
          <Note>
            {isSignUp
              ? t('AUTH_VIEW.ALREADY_HAVE_ACCOUNT')
              : t('AUTH_VIEW.DONT_HAVE_ACCOUNT_YET')}
          </Note>
          <Button
            dense
            uppercased
            color="secondary"
            shadowColored
            onClick={() => {
              if (isLogin) {
                handleOnClickSignUp();
              } else {
                handleOnClickSignIn();
              }
            }}
            style={{ maxWidth: '290px', margin: '0 auto' }}
          >
            {isSignUp ? t('LOGIN') : t('SIGNUP')}
          </Button>
        </Footer>
      ) : null}

      <Modal
        openModal={isForgotPassModalOpen}
        onClosedModal={() => {
          setIsForgotPassModalOpen(false);
          setEmailSent('');
        }}
      >
        {() =>
          emailSent
            ? <EmailSentView email={emailSent} />
            : <ForgotPasswordView onSuccess={(mail) => setEmailSent(mail)} />}
      </Modal>
    </StyledAuthView>
  );
};

export default AuthView;
