import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import Basic from '../styles/themes/TexterBasic';
import { Texter } from 'kinedu-react-components';
import Button from 'components/extended/Button';
import { t } from '../language/config';
import useAuth from '../hooks/useAuth';
import { isValidEmail } from '../lib/utils';

const StyledForgotPasswordView = styled.div`
  width: 100%;
  transition: all 0.5s ease-in;
`;

const Container = styled.div`
  max-width: 418px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
`;

const Title = styled.h3`
  font-weight: bold;
  font-size: 2rem;
  margin: 0;
  letter-spacing: 0;
  line-height: 47px;
  ${({ theme }) => `
    color: ${theme.colors.blue};

    ${theme.breakPoints.tablet} {
      font-size: 2.4rem;
    }
  `};
`;

const Subtitle = styled.span`
  font-size: 1.6rem;
  color: #333;
  letter-spacing: 0;
  font-weight: 300;
  line-height: 21px;
  text-align: center;
`;

const LabelsWrapper = styled.form`
  padding: 0px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 0;

  font-size: 18px;
  color: #657480;
  letter-spacing: 4.5px;
  text-transform: uppercase;
`;

const ErrorMessage = styled.span`
  display: inline;
  color: #ff0039;
  font-size: 1.2rem;
  text-align: left;
  letter-spacing: 0px;
  text-transform: none;
  padding: 10px 0;
`;

const ForgotPasswordView = ({ onSuccess = () => {} }) => {
  const { sendResetPasswordEmail } = useAuth();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isBusy, setIsBusy] = useState(false);

  const handleForgotPassword = (event) => {
    event.preventDefault();
    setError('');

    if (!isValidEmail(email)) {
      setError(t('ERRORS.INVALID_EMAIL'));

      return;
    }

    if (isBusy) {
      return;
    }

    setIsBusy(true);

    sendResetPasswordEmail(email)
      .then(() => {
        onSuccess(email);
        setIsBusy(false);
      })
      .catch(err => {
        console.log('%c🚫 err', 'background: #a20046; color: #ffa8a6; font-size: 16px', err);
        if (err) {
          let errorText = t('ERRORS.GENERAL_ERROR');

          if (err?.response?.data?.error === 'not found') {
            errorText = t('ERRORS.EMAIL_NOT_FOUND');
          }

          setError(errorText);
        }
        setIsBusy(false);
      });
  };

  const isButtonDisabled = useMemo(() => {
    return email === null || email === '';
  }, [email]);

  return (
    <StyledForgotPasswordView>
      <Container>
        <Title className="title">{t('FORGOT_PASS_VIEW.TITLE')}</Title>
        <Subtitle className="text">
          {t('FORGOT_PASS_VIEW.DESC')}
        </Subtitle>
        <LabelsWrapper id="labels" onSubmit={handleForgotPassword}>
          <InputGroup className="input-group" id="input-group-email">
            <Texter
              value={email}
              type="email"
              label="Email"
              theme={Basic}
              placeholder="example@email.com"
              onChange={setEmail}
            />
            <ErrorMessage>{error}</ErrorMessage>
          </InputGroup>
          <Button
            disabled={isButtonDisabled}
            shadowColored
            color="primary"
            style={{ maxWidth: '290px', margin: '0 auto' }}
            type="submit"
            fill
            small
          >
            {t('FORGOT_PASS_VIEW.SEND_EMAIL')}
          </Button>
        </LabelsWrapper>
      </Container>
    </StyledForgotPasswordView>
  );
};

export default ForgotPasswordView;
